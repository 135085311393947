export default class CustomGridDialog {
    extractBacklogDetails(txt) {
        const htmlString = txt;
        const pattern = /data-value="([^"]+)"/;
        console.log(htmlString);
        const match = htmlString.match(pattern);
        if (match) {
            const dataValue = match[1];
            this.getBacklogDetails(dataValue);
        } else {
            console.log("No match found.");
        }
    }

    getBacklogDetails(doc) {
        frappe.call({
            method: 'scrum.scrum.doctype.scrum_backlog.scrum_backlog.get_backlog_details',
            args: {
                doc: doc
            },
            callback: (response) => {
                if (response.message) {
                    var story = response.message.story;
                    var metadata = response.message.meta;
                    var assigned_users = response.message.assigned_users;
                    
                    if(assigned_users != null) {
                        var assignedUsersField = {
                            fieldname: 'assigned_users_html',
                            fieldtype: 'HTML',
                            label: __('Assigned Users'),
                            options: `
                                <div class="assigned-users-container">
                                    ${assigned_users.map(user => `
                                        <div class="assigned-user">
                                            <span>${user.allocated_to}</span>
                                        </div>
                                    `).join('')}
                                </div>
                                <style>
                                    .assigned-users-container {
                                        display: flex;
                                        flex-wrap: wrap;
                                        gap: 10px;
                                        margin-top: 10px;
                                    }
                                    .assigned-user {
                                        background-color: #f0f4f8;
                                        border-radius: 20px;
                                        padding: 5px 10px;
                                    }
                                </style>
                            `
                        };
                    
                        var columnBreak = {
                            fieldtype: 'Section Break'
                        };
                    }

                    
                    var fields = metadata.map(field => {
                        return {
                            fieldname: field.fieldname,
                            fieldtype: field.fieldtype,
                            label: __(field.label),
                            options: field.options,
                            default: story[field.fieldname]
                        };
                    });
                    
                    if(assigned_users != null){
                        fields.unshift(columnBreak);
                        fields.unshift(assignedUsersField);
                    }

                    var dialog = new frappe.ui.Dialog({
                        size: "extra-large",
                        title: __('Story Details'),
                        primary_action_label: __("Save"),
                        fields: fields,
                        primary_action: () => {

                            var updated_data = dialog.get_values();

                            // Set the updated data in the Scrum Story document

                            frappe.call({
                                method: 'frappe.client.set_value',
                                args: {
                                    doctype: 'Scrum Story',
                                    name: story.name,
                                    fieldname: updated_data
                                },
                                callback: function(r) {
                                    if (!r.exc) {
                                        frappe.msgprint(__('Scrum Story details updated successfully.'));
                                        dialog.hide();
                                    }
                                }
                            });
                        }
                    });
    
                    dialog.show();
                }
            }
        });
    }
}